
import {defineComponent, onMounted, reactive, toRefs, computed, ref, UnwrapRef, toRaw} from "vue";
import router from "@/router";
import StorageUtil from "@/utils/storage_util";
import PageUtil from "@/utils/page_util";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue";
import ApiExamination from "@/request/apis/api_examination";
import {useRoute} from "vue-router";
import {MinusCircleOutlined} from '@ant-design/icons-vue';

interface IState {
  list: any[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '题目',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '选项',
    dataIndex: 'questionOptions',
    key: 'questionOptions',
  },
  {
    title: '答案',
    dataIndex: 'answer',
    key: 'answer',
  },
  {
    title: '分数',
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '排序',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: '更新时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '操作',
    key: 'control',
  },
];

export default defineComponent({
  name: 'AppVersionList',
  components: {MinusCircleOutlined},
  setup() {
    const route = useRoute()
    const formRef = ref()
    const rules = {
      title: [{required: true, message: "请输入题目"},],
      number: [{required: true, message: "请输入编号"},],
      score: [{required: true, message: "请输入分数"},],
    }

    const types = [{id: 1, name: '单选'}, {id: 2, name: '判断'}, {id: 3, name: '多选'}, {id: 4, name: '填空'}, {
      id: 5,
      name: '任选'
    }]

    let formState: any = reactive({
      "title": "",//试卷名称
      "type": 1,//1单选 2判断 3多选 4填空 5任选
      "number": '',//编号
      "score": '',//分数
      "status": 1,//状态 0禁用 1启用
      "options": [],
      "answer": '',//答案 单选、判断、填空、任选填字符串 多选填数组
    })

    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches, paper_id: route.params.id}
      state.loading = true
      const res = await ApiExamination.getQuestionList(params);
      state.loading = false
      if (res && res.error_code === 0) {
        const data: any = res.data || []
        state.total = data.count
        state.list = data.list
      }
    }

    // 设置modal显示隐藏
    const setVisible = (flg: boolean, item?: any) => {
      state.visible = flg
      if (flg) {
        formState.title = item?.title || ''
        formState.number = item?.number || ''
        formState.status = item?.status || 1
        formState.type = item?.type || 1
        formState.score = item?.score || ''
        formState.id = item?.id || ''
        formState.options = item?.questionOptions || []
        if (item?.type == 4) {
          // 填空
          formState.answer = item?.answer || ''
        } else {
          // 选项
          let answer = item?.answer || ''
          if (Array.isArray(answer)) {
            // 多选
            formState.answer = item?.questionOptions.filter(e => answer.includes(e.index)).map(e => e.id)
          } else {
            formState.answer = item?.questionOptions.find(e => answer == e.index).id
          }
        }
      } else {
        setTimeout(() => {
          formRef.value.resetFields()
        }, 300)
      }
    }

    // 添加选项
    const addOptions = () => {
      formState.options = formState.options.concat([{
        sort: 1,//排序
        index: "",//选项唯一标识
        value: "",//选项内容
        score: "",//选项分数
        status: 1,//状态 0禁用 1启用
        key: new Date().valueOf()
      }])
    }

    // 删除选项
    const removeOptions = (index) => {
      formState.options = formState.options.filter((_, i) => i !== index);
    }

    const onTypeChange = e => {
      // 类型 1单选 2判断 3多选 4填空 5任选
      let type = e.target.value
      formState.options = []
      if (type == 2) {
        // 单选
        let key = new Date().valueOf()
        formState.answer = ''
        formState.options = [
          {
            sort: 1,//排序
            index: "A",//选项唯一标识
            value: "正确",//选项内容
            score: "",//选项分数
            status: 1,//状态 0禁用 1启用
            key: key
          },
          {
            sort: 1,//排序
            index: "B",//选项唯一标识
            value: "错误",//选项内容
            score: "",//选项分数
            status: 1,//状态 0禁用 1启用
            key: key + 1
          }
        ]
        return;
      }
      if (type == 3) {
        // 多选
        formState.answer = []
        return
      }
      formState.answer = ''

      if (type == 4) {
        // 填空题
        formState.options = []
      }
    }

    // 提交
    const onSubmit = () => {
      formRef.value
          .validate()
          .then(async () => {

            let params = {...toRaw(formState), paper_id: route.params.id}

            if ([1, 3, 5].includes(params.type) && !params.options.length) {
              message.destroy()
              message.warn('请添加选项')
              return
            }

            params.number = Number(params.number)
            params.score = Number(params.score)


            if (params.options.length) {

              if (params.options.some(e => !e.index || !e.value || e.score == '')) {
                message.destroy()
                message.warn('请完善选项')
                return
              }

              let answer: any = []
              params.options.map(e => {
                e.score = e.score ? Number(e.score) : 0
                delete e.create_time
                delete e.update_time
                delete e.question_id
                if (params.type == 3) {
                  // 多选
                  if (params.answer.includes(e.id || e.key)) {
                    answer.push(e.index)
                  }
                } else {
                  if (params.answer == e.id || params.answer == e.key) {
                    answer = e.index
                  }
                }
              })
              params.answer = answer
            }

            state.loading = true
            const res = params.id ? await ApiExamination.updateQuestion(params) : await ApiExamination.addQuestion(params);
            state.loading = false
            if (res && res.error_code == 0) {
              message.success(params.id ? '编辑成功' : '新增成功')
              state.searches.page = 1
              setVisible(false)
              await getList();
            } else {
              message.error(res?.msg || '操作失败，请重试');
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }

    const onDel = async (item: any) => {
      const res = await ApiExamination.delQuestion(item.id);
      if (res && res.error_code == 0) {
        message.success('删除成功')
        await getList();
      } else {
        message.error(res?.msg || '删除版本失败，请重试');
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    onMounted(() => {
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      types,
      handleTableChange,
      onSearch,
      resetSearch,
      onSubmit,
      setVisible,
      dateFormat,
      onDel,
      addOptions,
      onTypeChange,
      removeOptions,
      rules,
      formRef,
      formState,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
    }
  }
})
